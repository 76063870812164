@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

@font-face {
    font-family: avenir;
    src: url('../fonts/Avenir.ttc');
}

@font-face {
    font-family: avenir-bold;
    src: url('../fonts/AvenirLTStd-Black.otf');
}
@font-face {
    font-family: avenir-italic;
    src: url('../fonts/AvenirLTStd-BlackOblique.otf');
}
@font-face {
    font-family: avenir-standard;
    src: url('../fonts/AvenirLTStd-Book.otf')
}

h1, h2, h3 {
    margin-top: 20px !important;
    margin-bottom: 10px !important;
}
@use "sass:math";

@import 'src/sass/base';

$grid-square-proportion-mobile: 1;
$grid-square-proportion-tablet: 0.5;
$grid-square-proportion-desktop: 0.5;
$grid-square-proportion-large-desktop: 0.25;

$grid-square-large-proportion-mobile: 1;
$grid-square-large-proportion-tablet: 1;
$grid-square-large-proportion-desktop: 1;
$grid-square-large-proportion-large-desktop: 0.5;

$grid-square-padding: 10px;

$gutter-width-mobile: 0vw;
$gutter-width-tablet: 100vw * math.div(1, 6);
$gutter-width-desktop: 100vw * math.div(1, 6);
$gutter-width-large-desktop: 100vw * math.div(1, 2);

$gutter-width-webkit: 17px;
$gutter-width-firefox: 17px;
$gutter-width-edge: 12px;
$gutter-width-osx: 15px;

.grid-square-row {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  padding: 0 !important;
  margin: 0;

  a {
    text-decoration: none;
  }

  .grid-square, .grid-square-large {
    display: flex;
    padding: $grid-square-padding;

    .grid-square-image {
      display: flex;
      flex: 1;

      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    $grid-square-background-colour: #000000;
    .grid-square-overlay, .grid-square-hidden-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      align-items: stretch;

      padding: 10px;

      h2, p {
        text-align: center;
        font-family: avenir-bold;
        font-size: 16px;
        color: white;
      }
    }

    .grid-square-overlay {
      background-color: rgba($grid-square-background-colour, 0.1);
      transition: $transition;

      &:hover {
        background-color: rgba($grid-square-background-colour, 0.75);
        transition: $transition;
      }
    }

    @media (pointer: fine) {
      .grid-square-hidden-content {
        opacity: 0;
        transition: $transition;

        &:hover {
          opacity: 1;
          transition: $transition;
        }
      }
    }
  }
}

@mixin grid-proportions($browser-specific-gutter-width, $device-specific-gutter-width, $grid-square-proportion, $grid-square-large-proportion) {
    $device-adjusted-size: 100vw - $device-specific-gutter-width;
    $grid-square-size: $device-adjusted-size * $grid-square-proportion;
    $grid-square-large-size: $device-adjusted-size * $grid-square-large-proportion;

    $browser-adjusted-grid-square-size: calc(#{$grid-square-size} - (#{$browser-specific-gutter-width} * #{$grid-square-proportion}));
    $browser-adjusted-grid-square-large-size: calc(#{$grid-square-large-size} - (#{$browser-specific-gutter-width} * #{$grid-square-large-proportion}));

    .grid {
        margin-left: math.div($device-specific-gutter-width, 2);
    }

    .grid-square-row {
        .grid-square {
            width: $browser-adjusted-grid-square-size;
            height: $browser-adjusted-grid-square-size;
        }

        .grid-square-large {
            width: $browser-adjusted-grid-square-large-size;
            height: $browser-adjusted-grid-square-large-size;
        }
    }
}

@mixin mobile-grid-proportions($browser-specific-gutter-size) {
    @media (min-width: 0px) {
        @include grid-proportions(
            $browser-specific-gutter-size,
            $gutter-width-mobile,
            $grid-square-proportion-mobile,
            $grid-square-large-proportion-mobile
        );
    }
}

@mixin tablet-grid-proportions($browser-specific-gutter-size) {
    @media (min-width: 768px) {
        @include grid-proportions(
            $browser-specific-gutter-size,
            $gutter-width-tablet,
            $grid-square-proportion-tablet,
            $grid-square-large-proportion-tablet
        );
    }
}

@mixin desktop-grid-proportions($browser-specific-gutter-size) {
    @media (min-width: 992px) {
        @include grid-proportions(
            $browser-specific-gutter-size,
            $gutter-width-desktop,
            $grid-square-proportion-desktop,
            $grid-square-large-proportion-desktop
        );
    }
}

@mixin large-desktop-grid-proportions($browser-specific-gutter-size) {
    @media (min-width: 1200px) {
        @include grid-proportions(
            $browser-specific-gutter-size,
            $gutter-width-large-desktop,
            $grid-square-proportion-large-desktop,
            $grid-square-large-proportion-large-desktop
        );
    }
}

@mixin browser-adjusted-grid-proportions($browser-specific-gutter-size) {
    @include mobile-grid-proportions($browser-specific-gutter-size);
    @include tablet-grid-proportions($browser-specific-gutter-size);
    @include desktop-grid-proportions($browser-specific-gutter-size);
    @include large-desktop-grid-proportions($browser-specific-gutter-size);
}

// Browser queries driven from: http://browserhacks.com/
// Edge detection
@supports (-ms-ime-align:auto) {
    @include browser-adjusted-grid-proportions($gutter-width-edge);
}

// Firefox detection
@media screen and (-moz-images-in-menus:0) {
    @include browser-adjusted-grid-proportions($gutter-width-firefox);
}

// Chrome/Safari detection
@media screen and (-webkit-min-device-pixel-ratio:0) {
    @include browser-adjusted-grid-proportions($gutter-width-webkit);
}

// Touchscreen device - ignores all above browser queries and overrides them
@media (pointer: coarse), (pointer: none) {
    @include browser-adjusted-grid-proportions(1px);
}

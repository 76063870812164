@import 'src/sass/mixins';

.footer {
  @include url-cache-bust('bgs/bg_2022.jpg');

  height: 40px;
  width: 100%;
  margin-top: 20px;
  background-size: cover;
  background-position: bottom;

  p, a {
    margin-bottom: 0;
    color: white;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
  }
}

.social {
  width: 100%;
  margin: 20px 0;
  text-align: center;

  a {
    text-decoration: none;
  }

  $imgSize: 30px;
  img {
    margin: 0 10px;
    height: $imgSize;
    width: $imgSize;
  }
}

@import 'src/sass/variables';

.header {
  padding-top: 40px;
  padding-bottom: 5px;

  .navbar-custom {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    #logo {
      display: flex;
      flex: 1;
      flex-direction: column;

      span {
        font-size: 30px;
        font-family: 'Permanent Marker', cursive;
        color: black;
        line-height: 28px;

      }
    }

    a, b {
      display: inline-block;
      color: black;
      font-size: 19px;
      text-decoration: none;
    }

    b {
      font-family: avenir-bold;
      margin-left: 10px;
      padding: 0px 5px;
    }

    nav {
      a {
        font-family: avenir-bold;
        padding: 0px 5px;
        text-decoration: none;
        margin-left: 10px;
        border-bottom: 2px solid transparent;

        &.active {
          border-bottom-color: $font-colour;
        }

        &:hover {
          border-bottom: 2px solid $font-colour;
          color: $font-colour;
        }
      }
    }
  }
}

@import 'src/sass/base';

.animated-border-button {
  a {
    display: inline-block;
    color: white;
    padding: 5px calc(5px * 1.2);
    border: 3px solid transparent;
    position: relative;
    font-size: 16px;
    cursor: pointer;
    letter-spacing: 0.07em;

    .text {
      display: flex;
      align-items: center;
      transform: translate3d(0, 5px, 0);
      transition: transform 0.4s cubic-bezier(0.2, 0, 0, 1) 0.4s;
      font-family: avenir-bold;
    }

    .line {
      &.-left, &.-right {
        width: 3px;
        bottom: -3px;
        top: -3px;
        transform: scale3d(1, 0, 1);
      }

      &.-top, &.-bottom {
        height: 3px;
        left: -3px;
        right: -3px;
        transform: scale3d(0, 1, 1);
      }

      &.-right {
        right: -3px;
        transition: transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.23s;
        transform-origin: top;
      }

      &.-top {
        top: -3px;
        transition: transform 0.08s linear 0.43s;
        transform-origin: left;
      }

      &.-left {
        left: -3px;
        transition: transform 0.08s linear 0.51s;
        transform-origin: bottom;
      }

      &.-bottom {
        bottom: -3px;
        transition: transform 0.3s cubic-bezier(1, 0, 0.65, 1.01);
        transform-origin: right;
      }
    }

    &:after {
      position: absolute;
      content: "";
      bottom: -3px;
      left: calc(5px * 1.2);
      right: calc(5px * 1.2);
      height: 3px;
      background: $black;
      transition: transform 0.8s cubic-bezier(1, 0, 0.37, 1) 0.2s, right 0.2s cubic-bezier(0.04, 0.48, 0, 1) 0.6s, left 0.4s cubic-bezier(0.04, 0.48, 0, 1) 0.6s;
      transform-origin: left;
    }

    &:hover {
      text-decoration: none;
    }

    &:hover, &:active {
      .text {
        transform: translate3d(0, 0, 0);
        transition: transform 0.6s cubic-bezier(0.2, 0, 0, 1) 0.4s;
        font-family: avenir-bold;
      }

      .line {
        transform: scale3d(1, 1, 1);

        &.-right {
          transition: transform 0.1s cubic-bezier(1, 0, 0.65, 1.01) 0.2s;
          transform-origin: bottom;
        }

        &.-top {
          transition: transform 0.08s linear 0.4s;
          transform-origin: right;
        }

        &.-right {
          transition: transform 0.08s linear 0.48s;
          transform-origin: top;
        }

        &.-bottom {
          transition: transform 0.5s cubic-bezier(0, 0.53, 0.29, 1) 0.56s;
          transform-origin: left;
        }
      }
    }

    &:after {
      transform: scale3d(0, 1, 1);
      right: -3px;
      left: -3px;
      transform-origin: right;
      transition: transform 0.2s cubic-bezier(1, 0, 0.65, 1.01) 0.17s, right 0.2s cubic-bezier(1, 0, 0.65, 1.01), left 0s 0.3s;
    }
  }

  &.large {
    a {
      font-size: 20px;
    }
  }

  .line {
    position: absolute;
    background: $black;
  }
}

@media screen and (max-width: 600px) {
  .animated-border-button {
    &.large {
      a {
        font-size: 16px;
      }
    }
  }

  .landing {
    b, h1 {
      font-size: 50px;
    }
  }
}

.landing {
  @include url-cache-bust('landingpage_2022.jpg');

  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  h1 {
    font-family: 'Permanent Marker', cursive;;
    font-size: 70px;
    margin-bottom: 0;
  }

  .row-landing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100vh;
  }

  p {
    font-family: avenir-bold;
    padding-left: 5px;
    font-size: 18px;
  }
}

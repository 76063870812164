@import 'src/sass/base';

body {
    margin: 0;

    * {
        color: $font-colour;
        font-family: 'Outfit', sans-serif;
        font-weight: 400;
        color: #333;
    }

    b {
        font-family: avenir-bold;
        font-size: 19px;
    }

    p, a {
        font-size: 15px;
    }

    a {
        color: $purple-dark;
    }
    h1, h2, h3, h4, h5, h6 {
        font-family: avenir-bold;
    }
    h1 {
        font-size: 19px;
        font-family: avenir-bold;
        color: black;
        margin-top: 10px;
    }

    h2 {
        font-size: 15px;
        font-family: 'Outfit', sans-serif;
        font-weight: 600;
    }

    h4 {
        font-size: 14px;
    }
}

.separator {
    @include url-cache-bust('bgs/bg_2022.jpg');

    margin-top: 20px;
    margin-bottom: 20px;
    background-size: cover;
    background-position: center;

    > div {
        padding: 4.5px;

        > div {
            padding: 6px 13px;
            background-color: rgba(255, 255, 255, 0.9);
        }
    }

    textarea {
        width: 100%;
        background-color: transparent;
        resize: none; /*remove the resize handle on the bottom right*/
    }
}
.separator_1 {
    @include url-cache-bust('warehouse/warehouse_night1.png');

    margin-top: 20px;
    margin-bottom: 20px;
    background-size: cover;
    background-position: right;
    border:4px solid rgb(240, 228, 247);

    h2 {
        margin-bottom: 40px;
        font-family: avenir-bold;
        font-size: 28px;
    }

    > div {
        padding: 8px;
    }
}
.separator_2 {
    @include url-cache-bust('genart/genart_render.png');

    margin-top: 20px;
    margin-bottom: 20px;
    background-size: cover;
    background-position: center;
    border:4px solid rgb(240, 228, 247);
    height: 270px;

    > div {
        padding: 8px;
    }
}
.separator_3 {
    @include url-cache-bust('steampunk/steampunk_render.jpg');

    margin-top: 20px;
    margin-bottom: 20px;
    background-size: cover;
    background-position: center;
    border:4px solid rgb(240, 228, 247);
    height: 270px;

    > div {
        padding: 8px;
    }
}
.separator, .separator_1 {
    h1, h2, h3, h4 {
        margin-bottom: 40px;
        font-family: avenir-bold;
        font-size: 19px;
    }

    p {
        margin: 20px 0;
    }

    > div {
        > div {
            padding: 10px 20px;
            background-color: rgba(255, 255, 255, 0.9);

            > *:nth-child(1) {
                margin-top: 0;
            }

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.container-fluid {
    img {
        text-align: center;
        width: 100%;
    }
}

.twitter-tweet {
    margin: auto;
}


// Body
$body-bg: #f8fafc;

// Typography
$font-colour: rgb(40, 40, 40);

// Colours
$purple-light: rgb(201, 204, 247);
$purple-dark: rgb(144, 25, 224);

// Tag Colours
$tag1: rgb(230, 145, 9);
$tag2: $purple-dark;
$tag3: #2FB6C9;
$tag4: #6E1DDE;
$tag5: rgb(238, 196, 10);
$tag6: #2545E6;
$tag7: rgb(38, 73, 119);
$tag8: #e070d7;

// Animations
$transition: 0.4s ease;
.no-padding {
    padding: 0;
}

.padding-top {
    padding-top: 2px;
}

.padding-top-img {
    padding-top: 20px;
}

.padding-right {
    padding-right: 20px;
}

.inline-block {
    display: inline-block;
}